.modal {
    display: flex !important;
    align-items: center;
}

.account {
    margin-bottom: 30px;
    width: 350px;
}

.account-name {
    padding-bottom: 10px;
}

.role {
    padding-top: 15px;
}

.role button {
    width: 300px;
    margin: 0 auto;
}

.tokenmachineparent {
    margin-top: 30px;
    margin-bottom: 50px;
}

#accountlist {
    margin-top: 30px;
    display: flex !important;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
