body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

#root {
  min-height: 75vh;
}
.Home .lander {
    padding: 80px 0;
    text-align: center;
  }
  
  .Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }
  
  .Home .lander p {
    color: #999;
  }
.NotFound {
    padding-top: 100px;
    text-align: center;
  }
.modal {
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-align-items: center;
            align-items: center;
}

.account {
    margin-bottom: 30px;
    width: 350px;
}

.account-name {
    padding-bottom: 10px;
}

.role {
    padding-top: 15px;
}

.role button {
    width: 300px;
    margin: 0 auto;
}

.tokenmachineparent {
    margin-top: 30px;
    margin-bottom: 50px;
}

#accountlist {
    margin-top: 30px;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
